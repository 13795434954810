import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const useStyles = makeStyles((theme) => ({
    sidebarAboutBox: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.grey[200],
    },
    sidebarSection: {
        marginTop: theme.spacing(3),
        color: '#004d40',
    },
    brandColor: {
        color: '#004d40',
    }
}));

export default function Locate(props) {
    const classes = useStyles();
    const address = "The Nilgiris, India";

    return (
        <Grid item xs={12} md={4}>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                Locate Us
      </Typography>
            <Link href="locateUs" className={classes.brandColor}>
                <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                        <LocationCityIcon />
                    </Grid>
                    <Grid item xs={12} md={4}>{address}</Grid>
                </Grid>
            </Link>
            <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
                View on Map
      </Typography>
            <Link href="locateUs" className={classes.brandColor}>
                <Grid container direction="row" spacing={1} alignItems="center">
                    <Grid item>
                        <LocationOnIcon />
                    </Grid>
                    <Grid item xs={12} md={4}>{address}</Grid>
                </Grid>
            </Link>
        </Grid>
    );
}
