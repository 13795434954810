import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';



const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#e0f2f1',
        fontWeight: 'Bold',
        fontSize: 18,
        
    },
    body: {
        fontSize: 16,
        color: '#004d40',
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Consult with Experts', 305),
    createData('Tea Sample', 262),
    createData('Private labelling', 237),
    createData('Blending', 159),
    createData('Support (24*7)', 159),
];

const useStyles = makeStyles((theme) => ({
    heroContent: {
        marginTop: theme.spacing(4),
    },
    tableContainer: {
        marginBottom: theme.spacing(4),
        width: '60%',
        color: '#004d40',
        marginLeft: "auto",
        marginRight: "auto",
    },
    title: {
        color: '#004d40',
    },
    subtitle: {
        color: '#009688',
    },
}));

export default function Plan() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <Container>
                <Container maxWidth="sm" component="main" className={classes.heroContent} id="grade">
                    <Typography component="h4" variant="h4" align="center" className={classes.title} gutterBottom>
                        Gen Exports Benefits
        </Typography>
                    <Typography variant="h6" align="center" className={classes.subtitle} component="p">
                        Clients can leverage additional services by Gen Exports to deliver the best quality Tea to the users.
        </Typography>
                </Container>
                <TableContainer component={Paper} className={classes.tableContainer}>
                    <Table className={classes.table} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>Services</StyledTableCell>
                                <StyledTableCell></StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <StyledTableRow key={row.name}>
                                    <StyledTableCell component="th" scope="row">
                                        {row.name}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">✓</StyledTableCell>
                                </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Container>
        </React.Fragment>
    );
}