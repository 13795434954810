import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Markdown from './Markdown';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
    markdown: {
        ...theme.typography.body2,
        paddingTop: theme.spacing(3, 0),
    },
    typography: {
        color: "#00695c",
    },
}));

class BlogDescription extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            post: {
                path: props.post,
                currentValue: "Loading..."
            },
            title: props.title
        };

    }
    componentDidMount() {
        var { post } = this.state;
        post.currentValue = '';
        this.setState({ post });
        fetch(post.path)
            .then(response => response.text())
            .then(text => {
                console.log(text);
                post.currentValue = text;
                this.setState({ post });

            });
    }

    render() {
        const { classes } = this.props;
        const { post, title } = this.state;

        return (
            <Container>
                <Typography variant="h6" gutterBottom style={{color: "#00695c"}}>
                    {title}
                </Typography>
                {/* <Divider style={{backgroundColor: "#00695c"}}/> */}
                <CssBaseline />
                <Paper>
                <Markdown className={classes.markdown} key={post.currentValue.substring(0, 40)} style={{}}>
                    {post.currentValue}
                </Markdown>
                </Paper>
            </Container>)
    }
}
export default withStyles(useStyles)(BlogDescription)