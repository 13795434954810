import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        }
    },
    heroContent: {
        backgroundColor: '#fafafa',
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),

    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        backgroundColor: '#fafafa',
        padding: theme.spacing(3),
    },
    brandColor: {
        color: '#004d40',
    },
    subtitle: {
        color: '#009688',
    },
}));

function getSteps() {
    return ['Withering', 'Rolling', 'Fermenting', 'Drying', 'Sorting'];
}

function getStepContent(step) {
    switch (step) {
        case 0:
            return `The leaves are put on so called withering tables. Long tables with fine mesh as a table board that is being aired with a ventilation system from underneath. Fresh leaves contain about 73-80% of moisture, after 10 to 20 hours of withering the leaves’ moisture content will go down to about 60-65%. For a good quality tea it is of utmost importance that the withering is done evenly.`;
        case 1:
            return `After withering the soft tea leaves are put into rolling machines – two large round metal plates that run in different directions and gently twist the leaves thus opening the cell walls of the leaf and allowing the juices inside to get exposed to the oxygen in the air – the start of the oxidation process or fermentation.
            It takes a lot of expertise to decide upon the right amount of pressure and the right duration of the rolling process (usually about 20 to 60 minutes) to bring out the best results.`;
        case 2:
            return `The oxidation process that has been started during withering and rolling reaches its climax during the fermenting process that will last 2 to 5 hours. During this time black tea will develop its characteristic flavour and aroma while losing of its adstringent and bitter taste.`;
        case 3:
            return `Drying of firing of tea leaves is a tricky step in the manufacturing of tea. On flat conveyor belts the tea runs through a giant oven and is exposed to hot air, around 95°C. The heat will dry the tea leaves, giving them the dark colour and stop the fermentation process. Moisture content will be reduced to about 3 to 4 percent and ensure that tea can be stored for a long time. The drying process takes about 20 to 25 minutes.`
        case 4:
            return `Mechanical sieves will sort the tea according to different leaf grades.`
        default:
            return 'Unknown step';
    }
}

export default function TeaProcess() {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    return (
        <React.Fragment>
            <CssBaseline />
            <Container maxWidth="md" component="main" className={classes.heroContent} id="manproc">
                <Typography component="h5" variant="h4" align="center" className={classes.brandColor} gutterBottom>
                    Tea Manufacturing Process
        </Typography>
                <Typography variant="h6" align="center" className={classes.subtitle} component="p">
                    Tea leaf is grown in hilly regions with certain restricted environmental conditions. After Tea leaf plucking is done from Tea Garden, it is transported to the factory where the below process takes place. No adulteration in our manufacturing process.
        </Typography>
            </Container>
            <Container maxWidth="lg" component="main" className={classes.brandColor}>
                <Stepper activeStep={activeStep} orientation="vertical" className={classes.brandColor}>
                    {steps.map((label, index) => (
                        <Step key={label} className={classes.brandColor}>
                            <StepLabel className={classes.brandColor}>{label}</StepLabel>
                            <StepContent>
                                <Typography>{getStepContent(index)}</Typography>
                                <div className={classes.actionsContainer}>
                                    <div>
                                        <Button
                                            disabled={activeStep === 0}
                                            onClick={handleBack}
                                            className={classes.brandColor}
                                        >
                                            Back
                  </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={handleNext}
                                            className={classes.button}
                                        >
                                            {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                                        </Button>
                                    </div>
                                </div>
                            </StepContent>
                        </Step>
                    ))}
                </Stepper>
                {activeStep === steps.length && (
                    <Paper square elevation={0} className={classes.resetContainer}>
                        <Typography>Tea is ready for consumption.</Typography>
                        <Button onClick={handleReset} className={classes.button}>
                            Replay
          </Button>
                    </Paper>
                )}
            </Container>
        </React.Fragment>
    );
}