import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div><b>{text}</b></div>;

class LocateUs extends Component {
  static defaultProps = {
    center: {
      lat: 11.28,
      lng: 76.68
    },
    zoom: 11
  };

  render() {
    return (
      // Important! Always set the container height explicitly
      <div style={{ height: '100vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyDcc6MTZEXdfbxMJD77Vtea8Otw2wqtmF0' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
        >
          <AnyReactComponent 
            lat={11.361582}
            lng={76.805675}
            text="Gen Exports"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default LocateUs;