import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import BusinessIcon from '@material-ui/icons/Business';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
   
    heroContent: {
        marginBottom: theme.spacing(2),
        color: '#00695c',
    },
    external: {
        color: '#E53935'
      },
      button: {
        backgroundColor: '#303F9F',
        color: '#ffffff',
        "&:hover": {
            backgroundColor: '#ffffff',
            color: '#303F9F',
        }
      },
  
    
}));


export default function RetailSite() {
    const classes = useStyles();

    return (
        <React.Fragment>
            {/* Hero unit */}
            <Container className={classes.heroContent} maxWidth='md' id="retail">
            <Typography variant="subtitle1" className={classes.external} align="center">
              You are viewing B2B site. For retail purchase view &nbsp;
              <Button href="https://teashop.genexports.com" className={classes.button} variant="outlined" size="medium">
              Retail Site
            </Button>
            </Typography>


            </Container>
        </React.Fragment>
    );
}