import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Container } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';

const useStyles = makeStyles(theme => ({
    root: {
        justifyContent: 'center'
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
        color: "#004d40"
    },
    advisory: {
        marginBottom: theme.spacing(4),
    },
    cardMedia: {
        width: 160,
    },
    insight: {
        color: "#4db6ac",
    },
    heroContent: {
        marginTop: theme.spacing(4),
    },
    title: {
        color: '#004d40',
    },
    subtitle: {
        color: '#009688',
    },
    authorTitle: {
        color: '#000000'
    },
    content: {
        color: '#00695c'
    },
    button: {
        color: '#4db6ac',
        backgroundColor: '#e0f2f1',
        padding: '5px',
    },
}));

export default function Advisory() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            <Container className={classes.heroContent} id="advisory">
                <Container maxWidth="sm" component="main">
                    <Typography component="h4" variant="h4" align="center" className={classes.title} gutterBottom>
                        Expert Advisory
                    </Typography>
                    <Typography variant="h4" align="center" className={classes.authorTitle}>
                        Dr S Ramu
                    </Typography>
                    <Typography variant="h6" align="center" className={classes.authorTitle}>
                        Expert Advisory and Co-founder of Gen Exports
                    </Typography>
                    <Typography variant="body1" align="center" className={classes.subtitle}>
                        Over 4 decades of experience in Tea Cultivation, Tea Manufacturing, Quality Control, Tea Tasting, Tea Marketing, etc
                    </Typography>

                </Container>
                <Grid container spacing={5} className={classes.heroContent} >
                    <Grid item xs={12} md={6} className={classes.advisory}>
                        <Card className={classes.card}>
                            <div className={classes.content}>
                                <CardContent>
                                    <Typography gutterBottom component="h3" align="center" className={classes.title} variant="h5">
                                        Present Position
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Founder President, Small Tea Growers Association Of Southern India (STASI)
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Executive Commitee Member, Confederation of Indian Small Tea Growers Association(CISTA)
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Consultant, Solidaridad
                                    </Typography>
                                    <CardActions classes={{ root: classes.root }}>
                                        <Button size="small" className={classes.button} href='/contact#contactUs'>
                                            Contact Me
                                        </Button>
                                    </CardActions>
                                </CardContent>
                            </div>
                            {/* <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden> */}
                        </Card>
                        <Card className={classes.heroContent}>
                            <div className={classes.content}>
                                <CardContent>
                                    <Typography gutterBottom align="center" component="h2" variant="h5" className={classes.title}>
                                        Positions Held
                                    </Typography>
                                    <Typography variant="subtitle2" align="center">
                                        Member, Tea Board of India, Ministry of Commerce & Industry, Government of India
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" >
                                        Member, National Advisory Committee on Small Tea Growers
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" >
                                        Member, Tea Research Liaison Committee
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" >
                                        President, Nilgiri Bought Leaf Tea Manufacturers Association
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" >
                                        President, Nilgiri Small Tea Growers Association
                                    </Typography>
                                    <Typography variant="subtitle2" align="center" >
                                        Senior Scientist & Head, UPASI Krishi Vigyan Kendra, Indian Council of Agriculture Research(ICAR) Ministry of Agriculture, Govt. of India
                                    </Typography>
                                </CardContent>
                            </div>
                            {/* <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden> */}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6} className={classes.featuredPost}>
                        <Card>
                            <div className={classes.content}>
                                <CardContent>
                                    <Typography gutterBottom align="center" component="h2" variant="h5" className={classes.title}>
                                        Author of Tea Factory Manual
                                    </Typography>
                                    <Typography variant="h6" align="center">
                                        Contents
                                    </Typography>
                                    <Grid container>
                                        <Grid item xs={12} md={6} className={classes.advisory}>
                                            <Typography variant="subtitle2">
                                                1. Tea Overview
                                            </Typography>
                                            <Typography variant="body2">
                                                1.1 Tea Situation
                                            </Typography>
                                            <Typography variant="body2" noWrap>
                                                &nbsp;
                                            </Typography>
                                            <Typography variant="subtitle2" >
                                                2. Tea Technology
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1 CTC Tea Manufacture
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.a. Raw Material
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.b. Withering
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.c. Rolling
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.d. Fermentation
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.e. Drying
                                            </Typography>
                                            <Typography variant="body2">
                                                2.1.f. Sifting
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={6} className={classes.advisory}>
                                            <Typography variant="subtitle2">
                                                3. Tea Quality
                                            </Typography>
                                            <Typography variant="body2">
                                                3.1. Quality Control
                                            </Typography>
                                            <Typography variant="body2">
                                                3.2. Tea Tasting
                                            </Typography>
                                            <Typography variant="body2">
                                                3.3. Tea Grades and Cuppage
                                            </Typography>
                                            <Typography variant="body2" noWrap>
                                                &nbsp;
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                4. Tea And Health
                                            </Typography>
                                            <Typography variant="body2">
                                                4.1. Tea - A Health Promoting Beverage
                                            </Typography>
                                            <Typography variant="body2" noWrap>
                                                &nbsp;
                                            </Typography>
                                            <Typography variant="subtitle2">
                                                5. Tea Development
                                            </Typography>
                                            <Typography variant="body2">
                                                5.1. TeaBoard Loan Schemes
                                            </Typography>
                                            <Typography variant="body2">
                                                5.2. TIIC - SSI
                                            </Typography>
                                            <Typography variant="body2">
                                                5.3. Other Financial Institutions
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <CardActions classes={{ root: classes.root }}>
                                        <Button size="small" className={classes.button} href='/contact#contactUs'>
                                            Get A Copy
                                        </Button>
                                    </CardActions>
                                </CardContent>
                            </div>
                            {/* <Hidden xsDown>
            <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
          </Hidden> */}
                        </Card>

                    </Grid>
                </Grid>
            </Container>
        </React.Fragment>
    );
}