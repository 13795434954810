import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { sendGridEmail } from 'react-native-sendgrid';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';




function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '#004d40',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        name: { value: '', errorText: '' },
        organisation: { value: '', errorText: '' },
        phone: { value: '', errorText: '' },
        email: { value: '', errorText: '' },
      }, canSubmit: false,
      alert: { open: false, mailResponse: '', severity: '' }
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {

    const { formData } = this.state;
    event.preventDefault();

    let data = {
      'name': `${formData.name.value}`,
      'org': `${formData.organisation.value}`,
      'phno': `${formData.phone.value}`,
      'sender': `${formData.email.value}`,
    }

    const headers = {
      'Content-Type': 'application/json',
      'X-Authorization': 'AIzaSyDLlN-htUx6gS6ZV-WvM84vOyeT4HTjOWY'
    }
    const self = this;
    axios.post("https://us-east1-gen-exports-275913.cloudfunctions.net/email-notifications", data, {
      headers: headers
    }).then(function (response) {
      const formState = self.state;
      formState.alert.open = true;
      formState.alert.mailResponse = "Thank you! We will contact you shortly.";
      formState.alert.severity = "success";
      console.log(formState.formData.name.value);
      formState.formData.name.value = '';
      formState.formData.organisation.value = '';
      formState.formData.phone.value = '';
      formState.formData.email.value = '';
      self.setState({ formState });
    }).catch(function (error) {
      const formState = self.state;
      formState.alert.open = true;
      formState.alert.mailResponse = "Something went wrong, please check your internet and submit again.";
      formState.alert.severity = "error";
      self.setState({ formState });
    });
  }


  handleChange = (event) => {

    const formState = this.state;
    formState.canSubmit = false;
    formState.formData[event.target.name].errorText = '';

    if (event.target.name == 'name') {
      if (event.target.value.length < 1) {
        formState.formData[event.target.name].errorText = 'Name is Required field.';
      } else {
        var regex = /^[A-Za-z. ]+$/;
        if (!event.target.value.match(regex)) {
          formState.formData[event.target.name].errorText = 'Field should only contain A-Z.';
        }
      }
    } else if (event.target.name == 'email') {
      if (event.target.value.length < 1) {
        formState.formData[event.target.name].errorText = 'Email is Required field.';
      } else {
        var regex = /[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}/igm;
        if (!event.target.value.match(regex)) {
          formState.formData[event.target.name].errorText = 'Enter valid email.';
        }
      }
    } else if (event.target.name == 'organisation') {
      var regex = /^[A-Za-z.0-9 ]+$/;
      if (event.target.value.length > 0 && !event.target.value.match(regex)) {
        formState.formData[event.target.name].errorText = 'Field should only contain A-Z 0-9.';
      }
    } else if (event.target.name == 'phone') {
      var regex = /^[\d+ ]+$/;
      if (event.target.value.length > 0 && !event.target.value.match(regex)) {
        formState.formData[event.target.name].errorText = 'Should contain only numbers and +';
      }
    }
    formState.formData[event.target.name].value = event.target.value;
    this.setState({ formState });

    for (let [key, value] of Object.entries(formState.formData)) {
      if (value.errorText.length != 0) {
        return
      }
    }
    if ((formState.formData.name.value.length > 0) && (formState.formData.email.value.length > 0)) {
      formState.canSubmit = true;
    } else {
      formState.canSubmit = false;
    }

    this.setState({ formState });
  }

  handleClose = (event, reason) => {

    const formState = this.state;

    formState.alert.open = false;
    this.setState({ formState });

  };


  render() {
    const { classes } = this.props;
    const { formData, canSubmit, alert } = this.state;

    return (
      <Container component="main" maxWidth="xs" id="contactUs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <ContactMailIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Contact Us
        </Typography>
          <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="name"
                  label="Name"
                  name="name"
                  value={formData.name.value}
                  autoComplete="name"
                  helperText={formData.name.errorText}
                  error={(formData.name.errorText.length > 0)}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="organisation"
                  label="Organisation Name"
                  name="organisation"
                  value={formData.organisation.value}
                  autoComplete="organisation"
                  helperText={formData.organisation.errorText}
                  error={(formData.organisation.errorText.length > 0)}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  fullWidth
                  id="phone"
                  label="Phone Number"
                  name="phone"
                  value={formData.phone.value}
                  autoComplete="phone"
                  helperText={formData.phone.errorText}
                  error={(formData.phone.errorText.length > 0)}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  value={formData.email.value}
                  autoComplete="email"
                  helperText={formData.email.errorText}
                  error={(formData.email.errorText.length > 0)}
                  onChange={this.handleChange}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h7" component="h7">
                  Above provided information will not be shared to any third party services.
                </Typography>
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={!canSubmit}
            >
              Confirm
          </Button>
            <Snackbar open={alert.open} autoHideDuration={6000} onClose={this.handleClose}>
              <Alert onClose={this.handleClose} severity={alert.severity}>
                {alert.mailResponse}
              </Alert>
            </Snackbar>
          </form>
        </div>
      </Container>
    )
  }
}
export default withStyles(useStyles)(ContactUs)
