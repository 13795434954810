import React from 'react';
import ProductListing from './ProductListing';

const title = {
    main: "Featured Orthodox Tea",
    sub: "Orthodox tea is produced using traditional method."
}

const tiers = [
  {
    title: 'Green Tea',
    image: '/tea/greentea.png',
    description: 'Green tea is made from unoxidized leaves. It is rich in catechins and contains the most antioxidants and beneficial polyphenols.',
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  {
    title: 'White Tea',
    image: '/tea/whitetea.png',
    description: 'White tea is the least processed and it retains a high amount of antioxidants.',
    buttonText: 'Sign up for free',
    buttonVariant: 'outlined',
  },
  {
    title: 'Oolong tea',
    image: '/tea/oolongtea.png',
    description: 'It is neither a green tea or a black tea, rather something in between because it’s partially fermented and partially oxidized, giving it characteristics of both forms of tea.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function ProductListingCTC() {

  return (
    <React.Fragment>
        <ProductListing title={title} tiers={tiers} />
    </React.Fragment>
  );
}