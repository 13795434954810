import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import BlogDesc from './BlogDesc';

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));


export default function InsightsDetail(props) {
  const classes = useStyles();
  const { post, title } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <main>
          <Grid container spacing={5} className={classes.mainGrid}>
            <BlogDesc title={title} post={post} />
          </Grid>
        </main>
      </Container>
    </React.Fragment>
  );
}

InsightsDetail.propTypes = {
  post: PropTypes.object,
  title: PropTypes.object,
};