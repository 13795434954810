import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import StarIcon from '@material-ui/icons/StarBorder';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import BusinessIcon from '@material-ui/icons/Business';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    heroContent: {
        marginBottom: theme.spacing(4),
        marginTop: theme.spacing(4),
        color: '#00695c',
    },
  
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#004d40',
    },
    appBar: {
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    toolbar: {
        flexWrap: 'wrap',
    },
    toolbarTitle: {
        flexGrow: 1,
    },
    link: {
        margin: theme.spacing(1, 1.5),
    },
    cardHeader: {
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
    },
    cardPricing: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        marginBottom: theme.spacing(2),
    },
    footer: {
        borderTop: `1px solid ${theme.palette.divider}`,
        marginTop: theme.spacing(8),
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(6),
            paddingBottom: theme.spacing(6),
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    title: {
        color: '#004d40'
    }
}));

const tiers = [
    {
        title: 'Dust',
        description: [{ 'OP': 'Orange Pekoe' },
        { key: 'FP', value: 'Flowery Pekoe' },
        { key: 'BP', value: 'Broken Pekoe' },
        { key: 'BOP', value: 'Broken Orange Pekoe' },
        { key: 'FOP', value: 'Flowery Orange Pekoe' },
        { key: 'FBOP', value: 'Flowery Broken Orange Pekoe' },
        { key: 'GBOP', value: 'Golden Broken Orange Pekoe' },
        { key: 'GFOP', value: 'Golden Flowery Orange Pekoe' }],
        buttonText: 'Get started',
        buttonVariant: 'outlined',
    },
    {
        title: 'Leaf',
        description: [{ 'OP': 'Orange Pekoe' },
        { key: 'FP', value: 'Flowery Pekoe' },
        { key: 'BP', value: 'Broken Pekoe' },
        { key: 'BOP', value: 'Broken Orange Pekoe' },
        { key: 'FOP', value: 'Flowery Orange Pekoe' },
        { key: 'FBOP', value: 'Flowery Broken Orange Pekoe' },
        { key: 'GBOP', value: 'Golden Broken Orange Pekoe' },
        { key: 'GFOP', value: 'Golden Flowery Orange Pekoe' }],
        buttonText: 'Sign up for free',
        buttonVariant: 'outlined',
    },
    {
        title: 'Green Tea',
        description: [{ 'OP': 'Orange Pekoe' },
        { key: 'FP', value: 'Flowery Pekoe' },
        { key: 'BP', value: 'Broken Pekoe' },
        { key: 'BOP', value: 'Broken Orange Pekoe' },
        { key: 'FOP', value: 'Flowery Orange Pekoe' },
        { key: 'FBOP', value: 'Flowery Broken Orange Pekoe' },
        { key: 'GBOP', value: 'Golden Broken Orange Pekoe' },
        { key: 'GFOP', value: 'Golden Flowery Orange Pekoe' }
        ],
        buttonText: 'Contact us',
        buttonVariant: 'outlined',
    },
];

export default function Pricing() {
    const classes = useStyles();

    return (
        <React.Fragment>
            <CssBaseline />
            {/* Hero unit */}
            <Container className={classes.heroContent} maxWidth="md" component="main" id="whyus">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} >
                        <BusinessIcon />
                    </Avatar>
                </div>
                <Typography component="h4" variant="h4" align="center" gutterBottom className={classes.title}>
                    Why Us
                </Typography>
                <br />
                <Typography variant="h6" align="justify" component="p" className={classes.title}>
                The Company has around 100 acres tea garden and processing units in The Nilgiris. Different types of teas are being manufactured by procuring green leaf from small tea growers. As a social cause these manufacturing units are contributing a reasonable price to the small growers who are supplying leaf to the units.
                </Typography>
                <br />
                <Typography variant="h6" align="justify" component="p" className={classes.title}>
                In the estate, different types of green leafs are plucked for making value added teas like White Tea, Silver Tips, Green Tea, Orthodox and CTC. The company garden has planted different types of tea Jats namely Assam, China, Clonel tea plants to study the various quality parameters for making best high grown teas.
                </Typography>
                <br />
                <Typography variant="h6" align="justify" component="p" className={classes.title}>
                The Nilgiris is known as South India Darjeeling. In order to make the best tea on par with Darjeeling, the Company plantations and manufacturing units are contributing significantly. We have our own set of rich industry experts to monitor and analyse the source of green leaf from different region and elevation, quality manufacture, tasting, blending and sourcing to the requirement of the companies. We are also PAN India auction buyers. 
                </Typography>


            </Container>
        </React.Fragment>
    );
}