import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { fontFamily } from '@material-ui/system';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    minHeight: '10px'
  },
  toolbarTitle: {
    color: '#00695c',
    fontFamily: 'Roboto',
    fontWeight: 'bold',
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    color: '#004d40',
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;

  function Reserved() {
    return (
        <Typography color="inherit" display="inline" style={{"font-size": 'x-small'}}>
        </Typography>
    );
}

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
      
      </Toolbar>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
      <Typography
          component="h5"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
            {title}<Reserved />
        </Typography>
        {sections.map((section) => (
          <Link
          to="sections1"
            color="inherit"
            noWrap
            key={section.title}
            variant="body2"
            href={section.url}
            className={classes.toolbarLink}
          >
            {section.title}
          </Link>
        ))}
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
