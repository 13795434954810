import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Hidden from '@material-ui/core/Hidden';
import { Container, List } from '@material-ui/core';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Divider from '@material-ui/core/Divider';
import InsightsIcon from '@mui/icons-material/Insights';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@mui/material/Box';



const useStyles = makeStyles(theme => ({
    '@global': {
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
    },
    card: {
        display: 'flex',
    },
    cardDetails: {
        flex: 1,
    },
    ctc: {
        color: "#ffffff",
        backgroundColor: '#C62828',
    },
    orthodox: {
        color: "#ffffff",
        backgroundColor: '#2E7D32',
    },
    featuredPost: {
        marginBottom: theme.spacing(4),
        paddingRight: '10px',
    },
    list: {
        margin: '7px 0',
    },
    title: {
        color: '#004d40'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: '#004d40',
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    general: {
        color: '#bf360c'
    }
}));

export default function FeaturedPost(props) {
    const classes = useStyles();
    const { marketTrend, marketTrendBuyingPattern, marketTrendDate, marketGeneral } = props;

    return (
        <React.Fragment>
            <CssBaseline />
            <Container component="main">
                <div className={classes.paper}>
                    <Avatar className={classes.avatar} >
                        <InsightsIcon />
                    </Avatar>
                </div>
                <Typography variant="h4" align="center" className={classes.title} gutterBottom>
                    Market Trend
                </Typography>
                <Typography variant="h6" align="center" className={classes.title} gutterBottom>
                    {marketTrendDate}
                </Typography>
                <br />
                <Typography variant="h6" align="justify" className={classes.general} gutterBottom>
                    <Box sx={{ fontWeight: 'regular', m: 1 }}>{marketGeneral}</Box>
                </Typography>
                <br />
                <Grid container spacing={12}>
                    <Grid item xs={6} md={6} className={classes.featuredPost}>

                        <Card className={classes.card}>
                            <div className={classes.cardDetails}>
                                <CardContent className={classes.ctc}>
                                    <Typography variant="h4" align='center'>
                                        <Box sx={{ fontWeight: 500, m: 1 }}>{marketTrend[0].title}</Box>
                                    </Typography>
                                    {marketTrend[0].description.map((description) => (
                                        <Typography variant="subtitle1" className={classes.list} align='justify'>
                                            • {description}
                                        </Typography>
                                    ))
                                    }
                                </CardContent>

                            </div>
                            {/* <Hidden xsDown>
    <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
  </Hidden> */}
                        </Card>


                    </Grid>
                    <Grid item xs={6} md={6} className={classes.featuredPost}>

                        <Card className={classes.card}>
                            <div className={classes.cardDetails}>
                                <CardContent className={classes.orthodox}>
                                    <Typography variant="h4" align='center'>
                                        <Box sx={{ fontWeight: 500, m: 1 }}>{marketTrend[1].title}</Box>
                                    </Typography>
                                    {marketTrend[1].description.map((description) => (
                                        <Typography variant="subtitle1" className={classes.list} align='justify'>
                                            • {description}
                                        </Typography>
                                    ))

                                    }

                                </CardContent>

                            </div>
                            {/* <Hidden xsDown>
    <CardMedia className={classes.cardMedia} image={post.image} title={post.imageTitle} />
  </Hidden> */}
                        </Card>

                    </Grid>
                </Grid>
                <Typography variant="h6" align="justify" className={classes.general} gutterBottom>
                    <Box sx={{ fontWeight: 'regular', m: 1 }}>{marketTrendBuyingPattern}</Box>
                </Typography>
                <br />
                <Typography variant="subtitle1" align="justify" className={classes.title} gutterBottom>
                    <Box sx={{ fontStyle: 'italic', m: 1 }}>[Source : Global Tea Brokers, Coonoor]</Box>

                </Typography>

            </Container>
        </React.Fragment>
    );
}

FeaturedPost.propTypes = {
    post: PropTypes.object,
};
