import React from 'react';
import ProductListing from './ProductListing';

const title = {
    main: "Featured CTC Tea Grades",
    sub: "Grades are defined by the size of the tea leaf. It provides the core information about the tea itself."
}

const tiers = [
  {
    title: 'BOP',
    image: '/tea/BOP_new.png',
    description: 'Broken Orange Pekoe - Has better Colour, liquor and taste as compared to BOPS.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'BOPS',
    image: '/tea/BOPSM_new.png',
    description: 'Broken Orange Pekoe Souchong - Better taste, aroma and Colour.',
    buttonText: 'Get started',
    buttonVariant: 'outlined',
  },
  // {
  //   title: 'BOPF',
  //   image: '/tea/BOPF.png',
  //   description: 'Broken Orange Pekoe Fannings - Black-leaf tea with few added ingredients, uniform particle size, and no tips.',
  //   buttonText: 'Sign up for free',
  //   buttonVariant: 'outlined',
  // },
  {
    title: 'BP',
    image: '/tea/BP_new.png',
    description: 'Broken Pekoe - It usually has better Colour, taste and sufficient aroma as compared to other grades.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'BPSM',
    image: '/tea/BPSM_new.png',
    description: 'Broken Pekoe Small - Has good liquor, Colour and aroma.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'OF',
    image: '/tea/OF_new.png',
    description: 'Orange Fannings - Dust Grade. Gives immediate Colour and has an aroma.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'GD',
    image: '/tea/GD_new.png',
    description: 'Golden Pekoe - Dust Grade. Gives Colour and slight liquor.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  // {
  //   title: 'PD',
  //   image: '/tea/PD_new.png',
  //   description: 'Pekoe Dust - Dust grade. Twenty-six holes per inch.',
  //   buttonText: 'Contact us',
  //   buttonVariant: 'outlined',
  // },
  {
    title: 'RD',
    image: '/tea/RD_new.png',
    description: 'Red Dust - Dust grade. Gives Colour and slight liquor.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'SRD',
    image: '/tea/SRD_new.png',
    description: 'Super Red Dust - Dust grade. Gives Colour and slight liquor.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
  {
    title: 'SFD',
    image: '/tea/SFD_new.png',
    description: 'Super Fine Dust - Dust Grade. Gives Colour and slight liquor.',
    buttonText: 'Contact us',
    buttonVariant: 'outlined',
  },
];

export default function ProductListingCTC() {

  return (
    <React.Fragment>
        <ProductListing title={title} tiers={tiers} />
    </React.Fragment>
  );
}