import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CardMedia from '@material-ui/core/CardMedia';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    }
  },
  pricingTab: {
    marginBottom: theme.spacing(4),
  },
  heroContent: {
    marginTop: theme.spacing(4),
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  cardHeader: {
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[700],
  },
  cardPricing: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
  },
  footer: {
    borderTop: `1px solid ${theme.palette.divider}`,
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(6),
      paddingBottom: theme.spacing(6),
    },
  },
  root: {
    maxWidth: 345,
  },
  media: {
    height: 200,
  }
  ,
  title: {
    color: '#004d40',
  },
  subtitle: {
    color: '#009688',
  },
  button: {
    color: '#4db6ac',
    backgroundColor: '#e0f2f1',
    padding: '5px',
  },
}));

export default function ProductListing(props) {
  const classes = useStyles();
  const { title, tiers } = props;

  return (
    <React.Fragment>
      <CssBaseline />
      {/* Hero unit */}
      <Container maxWidth="sm" component="main" className={classes.heroContent} id="grade">
        <Typography component="h4" variant="h4" align="center" className={classes.title} gutterBottom>
          {title.main}
        </Typography>
        <Typography variant="h6" align="center" className={classes.subtitle} component="p">
          {title.sub}
        </Typography>
      </Container>
      {/* End hero unit */}
      <Container maxWidth="md" component="main" className={classes.pricingTab}>
        <Grid container spacing={5} alignItems="flex-end">
          {tiers.map((tier) => (
            // Enterprise card is full width at sm breakpoint
            <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
              <Card className={classes.root}>
                <CardMedia
                  className={classes.media}
                  image={tier.image}
                  title="Contemplative Reptile"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2" className={classes.title}>
                    {tier.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" component="p" className={classes.title}>
                    {tier.description}
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button size="small" className={classes.button} href='/contact#contactUs'>
                    Get Quote
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </React.Fragment>
  );
}

ProductListing.propTypes = {
  tiers: PropTypes.object,
  title: PropTypes.object,
};