import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import PhoneIcon from '@material-ui/icons/Phone';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Divider from '@material-ui/core/Divider';
import Sidebar from './Sidebar';
import Sidebar2 from './Sidebar2';
import ProductListingCTC from './ProductListingCTC'
import ProductListingOrthodox from './ProductListingOrthodox'
import ContactUs from './ContactUs'
import Footer from './Footer';
import Advertisement from './Advertisement';
import InsightsDetail from './InsightsDetail'
import TeaProcess from './TeaProcess';
import Locate from './Locate';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import LocateUs from './LocateUs';
import BlogDescription from './BlogDescription';
import cosmeticsPost from './cosmetics-blog.md';
import healthBenefitPost from './health-benefits-blog.md';
import Plan from './Plan';
import Advisory from './Advisory';
import MarketReport from './MarketReport';
import RetailSite from './RetailSite';


const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const sections = [
  { title: 'Home', url: '/' },
  // { title: 'Products', url: '#' },
  { title: 'Consult Expert', url: '/#advisory' },
  { title: 'Why Us', url: '/#whyus' },
  { title: 'Tea Grades', url: '/#grade' },
  { title: 'Manufacturing Process', url: '/manufacture' },
  // { title: 'Place Order', url: '#' },
  // { title: 'Payment', url: '#' },
  { title: 'Get Quote', url: '/contact#contactUs' },
  { title: 'Contact', url: '/contact#contactUs' }
];

const mainFeaturedPost = {
  title: 'Gen Tea Exports India',
  description:
    "Manufacturer and supplier of CTC and Orthodox Teas. A variety to choose from, including Black, Green and White Tea.",
  image: '/nilgiri-tea.jpeg',
  imgText: 'main image description',
  linkText: 'The Nilgiris  |  Cochin  |  Coimbatore  |  Kolkata  |  Guwahati  |  Siliguri',
};

const healthBenefitTitle = 'Health Benefits of tea';
const cosmeticsTitle = 'Top benefits of green tea in cosmetics';

const featuredPosts = [
  {
    title: healthBenefitTitle,
    date: 'Insight',
    description:
      'Tea, which is the world\'s most consumed beverage, after water can benefit your health in a number of ways. Tea comes from the Camellia sinensis plant and is often blended with other plants for different flavors, such as English breakfast, Earl Grey or chai.',
    imageText: healthBenefitTitle,
    link: '/insights/health-benefits',
  },
  {
    title: cosmeticsTitle,
    date: 'Insight',
    description:
      'Green tea is a type of tea that is made from Camellia sinensis leaves and has numerous benefits. When we talk about green tea as one of the most loved skincare ingredients on the market, we typically refer to an extract that is derived from green tea leaves.',
    imageText: cosmeticsTitle,
    link: '/insights/cosmetics',
  },
];
const marketGeneral = 'In General, in all auction centers Coonoor, Coimbatore & Cochin the market is up. Exporters, Packeteers and Internal are selective.';
const marketTrendDate = 'Sale No.12 – 25.03.2022';

const marketTrend = [
  {
    title: 'CTC',
    description: ['Leaf: The high priced and better liquoring sorts had fair demand and sold dearer by Rs.5 to 6 and more at times in line with quality, very occasionally some lots sold easier by Rs.3 to 4 with few withdrawals. The better medium sorts were barley steady to dearer by Rs.2 to 3 in line with quality. The mediums and plainers sorts had good demand and sold fully firm to dearer by Rs.1 to 2 and more at times. Generally a good demand was noticed in the overall CTC leaf sale.',
  'Dust: The high priced teas and better liquoring sorts were steady to easier by Rs.2 to 3 and more at times, very occasionally some quality lots sold dearer by Rs.2 to 3 and more at times. The better medium sorts were easier by Rs.3 to 4 and more with some withdrawals. The mediums and plainer sorts were barely steady to occasionally dearer by Rs.1 to 2.',
'Buying Pattern: Exporters are Selective. Packeteers are Selective. Internal Fairly Active.'],
  },
  {
    title: 'Orthodox',
    description:['The primary whole leaf grades were generally easier by Rs.2 to 3 and more at times, very occasionally some quality lots sold dearer by Rs.2 to 3 and more at times. The brokens were steady to dearer by Rs.3 to 4 and more at times in line with quality, occasionally few lots sold easier by Rs.4 to 5.. The secondary\'s and fanning were easier by Rs.2 to 3. Generally a fair demand was noticed in the overall ORTHODOX LEAF sale. The primary orthodox dust grades had fair demand and sold dearer by Rs.6 to 8 and more at times in line with quality. The secondaries and finer dusts were easier by Rs.2 to 3 with fair withdrawals.',
  'Buying Pattern: Exporters are Selectively Active, Internal Selectively Active.'],
  },
];
const marketTrendBuyingPattern = '';

const sidebar = {
  title: 'About',
  description:
    'Gen Tea Exports is primary exporter of Tea. We are manufacturer and supplier of CTC and Orthodox Teas. We have a vast experience in the tea industry and we help our customers with the right quality tea with the best price in the market.',
  archives: [
    { title: 'March 2020', url: '#' },
    { title: 'February 2020', url: '#' },
    { title: 'January 2020', url: '#' },
    { title: 'November 1999', url: '#' },
    { title: 'October 1999', url: '#' },
    { title: 'September 1999', url: '#' },
    { title: 'August 1999', url: '#' },
    { title: 'July 1999', url: '#' },
    { title: 'June 1999', url: '#' },
    { title: 'May 1999', url: '#' },
    { title: 'April 1999', url: '#' },
  ],
  social: [
    { title: 'Call Us', name: '+91-9345751926', icon: PhoneIcon },
  ],
};

export default function Blog() {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Container maxWidth="lg">
        <Header title="Gen Exports India" sections={sections} />
        <main>
          <Router>
            <Route path="/" exact render={(props) => <MainFeaturedPost post={mainFeaturedPost} />} />
            <Route path="/contact" exact render={(props) => <MainFeaturedPost post={mainFeaturedPost} />} />
            <Route path="/manufacture" exact render={(props) => <MainFeaturedPost post={mainFeaturedPost} />} />
          </Router>
          <Router>
        <Route path="/" exact component={RetailSite} />
      </Router>
      <Divider />
        </main>
      </Container>

      <Router>
        <Route path="/" exact render={(props) => <Container maxWidth="lg">
          <main>
            <Grid container spacing={4}>
              {featuredPosts.map((post) => (
                <FeaturedPost key={post.title} post={post} />
              ))}
            </Grid>
          </main>
        </Container>} />
      </Router>

    
      {/* <Divider />
      <Router>
      <Route path="/" exact render={(props) => <MarketReport marketTrend={marketTrend} marketTrendBuyingPattern={marketTrendBuyingPattern} marketTrendDate={marketTrendDate} marketGeneral={marketGeneral} />} />
      </Router> */}
      <Divider />
      <Router>
        <Route path="/" exact component={Advertisement} />
      </Router>
      <Divider />
      <Router>
        <Route path="/" exact component={Advisory} />
      </Router>
      <Divider />




      <Router>
      
        {/* <Route path="/" exact component={Pricing} /> */}
        
        <Route path="/" exact component={ProductListingCTC} />
        <Route path="/insights/cosmetics" render={(props) => <InsightsDetail title={cosmeticsTitle} post={cosmeticsPost} {...props} />} />
        <Route path="/insights/health-benefits" render={(props) => <InsightsDetail title={healthBenefitTitle} post={healthBenefitPost} {...props} />} />
        <Route path="/manufacture" exact component={TeaProcess} />
        <Route path="/locateUs" exact component={LocateUs} />
        <Route path="/contact" exact component={ContactUs} />
      </Router>
      <Divider />
      <Divider />
      <Router>
        <Route path="/" exact component={ProductListingOrthodox} />
      </Router>
      <Divider />
      <Divider />
      
      {/* <Divider />
      <Router>
        <Route path="/" exact component={TeaTaste} />
      </Router> */}
      <Router>
        <Route path="/" exact component={Plan} />
      </Router>
      <Divider />
      <Container maxWidth="lg">
        <CssBaseline />
        <Grid container spacing={5} className={classes.mainGrid}>
          <Sidebar
            title={sidebar.title}
            description={sidebar.description}
            archives={sidebar.archives}
            social={sidebar.social}
          />
          <Sidebar2
            title={sidebar.title}
            archives={sidebar.archives}
            social={sidebar.social}
          />
          {/* <Phone /> */}
          <Locate />
          {/* <SimpleMap /> */}
        </Grid>
      </Container>
      <Divider />
      <Footer />
    </React.Fragment>
  );
}
