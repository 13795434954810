import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Email from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';

const useStyles = makeStyles((theme) => ({
  sidebarAboutBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.grey[200],
  },
  sidebarSection: {
    marginTop: theme.spacing(2),
    color: '#004d40',
  },
  brandFontColor: {
    color: '#004d40',
  },
}));

export default function Sidebar(props) {
  const classes = useStyles();
  const email = 'info@genexports.com';
  const phone1 = '+91-9345751926';
  const phone2 = '+91-8903744535';

  return (
    <Grid item xs={12} md={4}>
      <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
        Write to Us
      </Typography>
      <Link display="block" variant="body1" href={"mailto:" + email} key={email} className={classes.brandFontColor}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Email />
          </Grid>
          <Grid item xs={12} md={4}>{email}</Grid>
        </Grid>
      </Link>

      <Typography variant="h6" gutterBottom className={classes.sidebarSection}>
        Call Us
      </Typography>
      <Link className={classes.brandFontColor}>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <PhoneIcon />
          </Grid>
          <Grid item xs={12} md={4}>{phone2}</Grid> 
        </Grid>
        <Grid container direction="row" spacing={1} alignItems="center">
          <Grid item>
            <PhoneIcon />
          </Grid>
          <Grid item xs={12} md={4}>{phone1}</Grid>
          
        </Grid>
      </Link>
    </Grid>


  );
}

Sidebar.propTypes = {
  social: PropTypes.array,
  title: PropTypes.string,
};
