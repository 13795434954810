import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';
import Paper from '@material-ui/core/Paper';
import Markdown from './Markdown';
import ScrollTop from './ScrollTop';
import Avatar from '@material-ui/core/Avatar';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';

const useStyles = theme => ({
    markdown: {
        ...theme.typography.body2
    },
    titlePost: {
        padding: theme.spacing(2, 2, 0, 2)
    },
    subtitlePost: {
        padding: theme.spacing(0, 2, 2, 2)
    },
    title: {
        backgroundColor: "#00695c",
        color: "white"
    },
    detail: {
        borderColor: "#e0f2f1",
        borderTopColor: "#00695c",
        color: "#004d40"
    }
});

class BlogDesc extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            post: {
                path: props.post,
                currentValue: "Loading..."
            },
            title: props.title
        };

    }
    componentDidMount() {
        var { post } = this.state;
        post.currentValue = '';
        this.setState({ post });
        fetch(post.path)
            .then(response => response.text())
            .then(text => {
                post.currentValue = text;
                this.setState({ post });

            });
    }

    render() {
        const { classes } = this.props;
        const { post, title } = this.state;
        const props = this.props;
        return (
            <Paper elevation={3} variant="outlined" className={classes.detail}>
                <Box id="back-to-top-anchor" className={classes.title} boxShadow={3}>
                    <Typography variant="h6" className={classes.titlePost}>{title}</Typography>
                    <Typography variant="subtitle2" className={classes.subtitlePost}>by Yeshwanthini</Typography>
                </Box>
                <Container>
                    <Box my={2}>
                        <Markdown className={classes.markdown} key={post.currentValue.substring(0, 40)}>
                            {post.currentValue}
                        </Markdown>
                    </Box>
                </Container>


                <ScrollTop {...props}>
                    <Fab size="small" aria-label="scroll back to top">
                        <KeyboardArrowUpIcon />
                    </Fab>
                </ScrollTop>
            </Paper>
        );
    }
}
export default withStyles(useStyles)(BlogDesc)